<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>智能填报</el-breadcrumb-item>
        <el-breadcrumb-item>普通类型填报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info">
      <el-row>
        <el-col :span="18">
          <span>科类:</span>
          <span class="info_2">{{ userInfo.subject == 1 ? '物' : '史' }}<span
              v-for=" (item, key) in JSON.parse(userInfo.other_subject) " :key="key">
              <span v-if="item == 2">化</span>
              <span v-if="item == 3">生</span>
              <span v-if="item == 6">政</span>
              <span v-if="item == 5">地</span>
            </span>
          </span>

          <span>分数:</span>
          <span class="info_2">{{ userInfo.score }}分</span>
          <span>位次:&nbsp;</span>
          <span class="info_2" v-if="rankingData != null">{{ rankingData.ranking }}</span>
          <span>换算位次</span>
          <span class="info_3" v-if="rankingData != null">{{ rankingData.ranking_1 }}</span>
          <span class="info_4">/23年</span>
          <span class="info_3" v-if="rankingData != null">{{ rankingData.ranking_2 }}</span>
          <span class="info_4">/22年</span>
          <span class="info_3" v-if="rankingData != null">{{ rankingData.ranking_3 }}</span>
          <span class="info_4">/21年</span>
        </el-col>

        <el-col :span="4" :offset="2">
          <span>填报批次：</span>
          <span class="tuijian"></span>
          <span class="select">
            <el-select v-model="new_batch" placeholder="请选择" class="searchBatch" @change="getBatch" size="mini">
              <div v-for="(item, i) in batchList" :key="i">
                <el-option :label="item.name" :value="item.id">
                </el-option>
              </div>
            </el-select>
          </span>
        </el-col>
      </el-row>

    </div>
    <div class="nav">
      <div :class="{ act: this.nav_num == 1 }" @click="chooseNav(1)">
        <p>院校优先填报</p>
        <p>以院校为优先级填报志愿</p>
      </div>
      <div :class="{ act: this.nav_num == 2 }" @click="chooseNav(2)">
        <p>专业优先填报</p>
        <p>以专业为优先级填报志愿</p>
      </div>
      <div :class="{ act: this.nav_num == 3 }" @click="chooseNav(3)">
        <p>我的志愿表</p>
        <p>当前已填{{ college_num }}志愿位置</p>
      </div>
    </div>

    <router-view @Nav="setNav" v-if="new_batch && rankingData" :new_batch="new_batch" :subject_id="subject_id"
      :rankingData="rankingData"></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import subjectList from "@/assets/data/subject.js";
export default {
  name: "Index",
  computed: {
    ...mapState(["userInfo", 'record']),
  },
  watch: {
    record() {
      console.log(12312)
      this.getNum()
    }
  },

  data() {
    return {
      nav_num: 0,
      college_num: 0,
      equalScore: [0, 0, 0, 0],

      new_batch: "1",
      subject: null,
      subject_id: null,
      batchList: [
        { id: "1", name: "本科批次", code: "1", status: "1" },
        { id: "2", name: "专科批次", code: "2", status: "1" },
      ],
      // record: {
      //   table: [],
      //   select_code: [],
      //   specialty: []
      // },
      rankingData: null,
      len: 48
    };
  },

  created() {
    //设置文理科
    this.subject = this.userInfo.subject

    //subject_id
    this.getSubjectId()

    // 获取等同分
    this.getEqualList();

    this.getRecode()
    this.getRanking()

  },
  methods: {

    //动态选择模块
    chooseNav(e) {
      this.nav_num = e;
      switch (e) {
        case 1:
          this.$router.push("/volunteer");
          return;
        case 2:
          this.$router.push("/volunteer/MajorFirst");
          return;
        case 3:
          this.$router.push("/volunteer/ReportTable");
          return;
      }
    },

    setNav(e) {
      this.nav_num = e;
    },

    //获取subject_id
    getSubjectId() {
      let other_subject = JSON.parse(this.userInfo.other_subject)
      if (other_subject.length == 0) {
        this.$router.push("/user/info");
      }

      var subject_array = [];
      other_subject.forEach(item => {
        subject_array.push(item)
      })
      //获取选科-把三个科目放到一起
      subject_array.push(this.userInfo.subject)

      subject_array = subject_array.map(function (item) {
        return Number(item);
      });
      //给科目排序
      subject_array.sort((a, b) => a - b)
      subject_array = subject_array.join('')
      this.subject_id = subjectList.indexOf(Number(subject_array))
    },

    //获取志愿表
    getRecode() {
      let data = {
        user_id: this.userInfo.id,
        subject: this.userInfo.subject,
        new_batch: this.new_batch,
      }
      this.$fecth.post("volunteer/getUserReport", data).then((res) => {
        let record = {
          table: [],
          select_code: [],
          specialty: []
        }
        if (res.record) {
          record = JSON.parse(res.record)
          for (let i = 0; i < this.len; i++) {
            if (!record.table[i]) {
              record.table.push({
                name: "暂无",
              });
              record.select_code.push('')
              record.specialty.push([])
            }
          }
        } else {
          for (let i = 0; i < this.len; i++) {
            record.table.push({
              name: "暂无",
            });
            record.select_code.push('')
            record.specialty.push([])
          }
        }
        this.$store.commit('setRecord', record)
      })
    },

    getBatch() {
      this.changeBatch(this.new_batch)
    },

    //设置批次
    changeBatch(e) {
      this.new_batch = e
    },

    // 获取等同分
    getEqualList() {
      let is_wenli = null
      if (this.subject == 1) {
        is_wenli = 2
      } else {
        is_wenli = 1
      }
      this.$fecth
        .post("score_equal/getEqualScore", {
          is_wenli: is_wenli,
          score: this.userInfo.score,
        })
        .then((res) => {
          this.equalScore = res;
        });
    },

    //获取用户位次信息
    getRanking() {
      this.$fecth.post("user_info/getRanking", { subject: this.userInfo.subject, score: this.userInfo.score }).then((res) => {
        if (res) {
          this.rankingData = res
        }
      });
    },

    getNum() {
      let i = 0
      this.record.select_code.forEach(item => {
        if (item) {
          i++;
        }
      })
      this.college_num = i
    }

  },
};
</script>

<style lang="less">
.searchBatch {
  .el-input__inner {
    border: 1px solid #2b7bf3;
    border-radius: 30px;
    height: 30px;
    line-height: 30px;
    width: 110px;
  }

  .el-select:hover {
    border-color: #2b7bf3 !important;
  }

  .el-input__icon {
    line-height: 30px !important;
  }
}
</style>
<style lang="less">
.el-input__inner {
  font-size: 16px;
}
</style>

<style lang="less" scoped>
.nav {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  div {
    width: 286px;
    background: #459df6;
    padding: 8px 57px;

    p:first-child {
      color: white;
      font-size: 18px;
    }

    p:last-child {
      font-size: 14px;
      color: white;
    }
  }
}

.act {
  background: #2871fb !important;
  color: white;
}

.info {
  font-size: 16px;
  position: relative;

  span {
    display: inline-block;
  }

  margin: 0 0 20px 0;

  .info_2 {
    color: #1787e0;
    margin-right: 25px;
  }

  .info_3 {
    color: #1787e0;
    margin-left: 15px;
  }

  .info_4 {
    color: #a5a3a2;
  }

  .tuijian {
    position: absolute;
    height: 20px;
    width: 55px;
    top: -16px;
    right: 10px;
    font-size: 10px;
    background: url("../../assets/img/volunteer/tuijianpc.png") no-repeat;
  }
}
</style>